<template>
  <div>
    <!-- Sign Up Image And Headings -->
    <div class="sign-up-header" style="background-image: url('images/bg-profile.jpg')">
      <div class="content">
        <h1 class="mb-5">Login DEV</h1>
        <p class="text-lg">Voer uw verkregen code hier in, om toegang te krijgen tot PairPlex DEV</p>
      </div>
    </div>
    <!-- / Sign Up Image And Headings -->

    <!-- Sign Up Form -->
    <a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
      <template #title>
        <h5 class="font-semibold text-center">Login</h5>
      </template>
      <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
      >
        <a-form-item class="mb-5">
          <a-input
              v-decorator="[
              'password',
              { rules: [{ required: true, message: 'Please input your Code!' }] },
            ]"
              type="password"
              placeholder="Password"
          >
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" block html-type="submit" class="login-form-button">
            LOGIN
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
    <!-- / Sign Up Form -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {name: "auth_login"});
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      const correctPassword = "PairPlex@2024"; // Correcte wachtwoord
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.password === correctPassword) {
            console.log("Received values of form: ", values);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("password", correctPassword); // Sla correct wachtwoord op in cache
            this.$router.push("/dashboard");
          } else {
            this.$message.error("Incorrect password!"); // Toon foutmelding
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
/* Add your styles here */
</style>

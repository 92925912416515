<template>

	<!-- Layout Footer -->
	<a-layout-footer>
		<div class="footer">

		<div style="position: absolute; bottom: 0; left: 0; right: 0; text-align: center;">
    <p class="copyright">
        © 2024 All rights reserved by <b style="color: rgb(24, 143, 253);">Pairplex</b>
    </p>
</div>
</div>


	</a-layout-footer>
	
	<!-- / Layout Footer -->


</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: white; /* Adjust background color if necessary */
}

.main-content {
  flex: 1;
}
</style>
<template>

	<!-- Layout Footer -->
	<a-layout-footer>

		<a-row>

	<a-col>

		<p class="copyright">
					© 2024 All rights reserved by <b style="color: rgb(24, 143, 253);">Pairplex</b>
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->


</template>

<script>

	export default ({
		data() {
			return {
			}
		},
	})

</script>

<style lang="scss" scoped>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>
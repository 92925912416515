import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '../views/Auth.vue';

Vue.use(VueRouter);

let routes = [
	{
		// Catch-all voor 404-pagina
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Home',
		redirect: '/dashboard',
		meta: { requiresAuth: true }, // Beveiligde route
	},
	{
		path: "/auth",
		name: "Auth",
		component: Auth, // Gebruik geïmporteerde component direct
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		layout: "dashboard",
		meta: { requiresAuth: true }, // Beveiligde route
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/tables',
		name: 'Tables',
		layout: "dashboard",
		meta: { requiresAuth: true },
		component: () => import('../views/Tables.vue'),
	},
	{
		path: '/billing',
		name: 'Billing',
		layout: "dashboard",
		meta: { requiresAuth: true },
		component: () => import('../views/Billing.vue'),
	},
	{
		path: '/profile',
		name: 'Profile',
		layout: "dashboard",
		meta: {
			layoutClass: 'layout-profile',
			requiresAuth: true, // Beveiligde route
		},
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
	{
		path: '/create',
		name: 'Create',
		layout: "dashboard",
		meta: { showSidebar: true, requiresAuth: true, },
		component: () => import('../views/Create.vue'),
	},
	{
		path: '/update',
		name: 'Update',
		layout: "dashboard",
		meta: { showSidebar: true, requiresAuth: true, },
		component: () => import('../views/Update.vue'),
	},
	{
		path: '/delete',
		name: 'Delete Connection',
		layout: "dashboard",
		meta: {
			layoutClass: 'dashboard',
			requiresAuth: true, // Beveiligde route
		},
		component: () => import('../views/deleteConnection.vue'),
	},
];

// Geoptimaliseerde functie om layout toe te voegen aan routes
function addLayoutToRoute(route, parentLayout = "default") {
	// Controleer of de layout al correct is ingesteld
	if (!route.meta || !route.meta.layout) {
		route.meta = route.meta || {};
		route.meta.layout = route.layout || parentLayout;
	}

	// Verwerk de children als ze bestaan
	if (route.children) {
		route.children = route.children.map(childRoute =>
			addLayoutToRoute(childRoute, route.meta.layout)
		);
	}
	return route;
}

// Pas de layout-functie toe op alle routes
routes = routes.map(route => addLayoutToRoute(route));

// Initialiseer de router
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			};
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		};
	},
});

// Router-guard voor authenticatie
router.beforeEach((to, from, next) => {
	const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
	const correctPassword = 'PairPlex@2024';
	const cachedPassword = localStorage.getItem("password");

	// Controleer of de cache het juiste wachtwoord bevat
	if (cachedPassword && cachedPassword !== correctPassword) {
		localStorage.clear(); // Verwijder cache als wachtwoord niet overeenkomt
	}

	if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
		next("/auth"); // Stuur naar auth-pagina
	} else {
		next();
	}
});

export default router;
